import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { uberButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href="https://www.order.store/ca/store/kooks-cooks/Yq49Thj0SoyobK63m7XKag"
              target="_blank"
            >
              <CFView hover>
                <CFImage src={uberButton} maxWidth="250px" alt="About" />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href="https://www.order.store/ca/store/kooks-cooks/Yq49Thj0SoyobK63m7XKag"
              target="_blank"
            >
              <CFView hover>
                <CFImage
                  src={uberButton}
                  w="100%"
                  maxWidth="240px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
