import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { viewMenu, menu } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getPdfMenuUrl}>
      {pdfMenuUrl => (
        <CFView>
          <MobileScreen>
            <a href={pdfMenuUrl} target="_blank">
              <CFView hover>
                <CFImage src={viewMenu} maxWidth="250px" alt="About" />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a href={pdfMenuUrl} target="_blank">
              <CFView hover>
                <CFImage src={viewMenu} w="100%" maxWidth="240px" alt="About" />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
