import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { promo1, promo2, promo3, mobilePromotion, promotions } from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobilePromotion} w="95%" alt="Mobile Promotions" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1400px">
          <CFImage src={promotions} w="80%" alt="Promotions" mb="50px" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
