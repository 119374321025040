import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import UberButton from './UberButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="150VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          justifyStart
        >
          <CFView textCenter column w="100%" h="80vh">
            <CFView column center>
              <CFImage src={mobileHeroText} w="90%" alt="Promotions" />
            </CFView>
            <CFView mt="20px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView mt="10px">
              <OrderDeliveryButton />
            </CFView>
            <CFView mt="10px">
              <UberButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="120vh"
          maxHeight="750px"
          w="100%"
          image={`url(${hero}) left / cover no-repeat`}
          zIndex={90}
          column
          justifyStart
          alignStart
        >
          <CFView column justifyCenter alignStart ml="6vw">
            <CFImage src={heroText} w="42vw" maxWidth="480px" alt="hero text" />
            <CFView mt="20px" ml="15px" pulsate w="24vw" maxWidth="240px">
              <OrderPickupButton />
            </CFView>
            <CFView mt="10px" ml="15px" w="24vw" maxWidth="240px">
              <OrderDeliveryButton />
            </CFView>
            <CFView mt="10px" ml="15px" w="24vw" maxWidth="240px">
              <UberButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
